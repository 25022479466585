export const formatDate = (date, includeTime = true, returnJustTime = false) => {
  const dateObj = new Date(date);
  let day = dateObj.getDate();
  day = day < 10 ? `0${day}` : day;
  const month = dateObj.toLocaleString('en-GB', { month: 'long' });
  const year = dateObj.getFullYear();

  const datePart = `${day} ${month} ${year}`;

  const timePart = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

  if (includeTime) {
    return `${datePart} (${timePart})`;
  }

  if (returnJustTime) {
    return `(${timePart})`;
  }

  return datePart;
};

export const formatExtraTime = (val, languageVariationCode, placementTestsContent) => {
  switch (val) {
    case 25:
      return `${placementTestsContent?.create_placement_test_extra_time_label_25} (${
        languageVariationCode
          ? placementTestsContent?.create_placement_test_extra_time_tooltip_25_lang
          : placementTestsContent?.create_placement_test_extra_time_tooltip_25
      })`;
    case 50:
      return `${placementTestsContent?.create_placement_test_extra_time_label_50} (${
        languageVariationCode
          ? placementTestsContent?.create_placement_test_extra_time_tooltip_50_lang
          : placementTestsContent?.create_placement_test_extra_time_tooltip_50
      })`;
    default:
      return `${placementTestsContent?.create_placement_test_extra_time_label_0} (${
        languageVariationCode
          ? placementTestsContent?.create_placement_test_extra_time_tooltip_0_lang
          : placementTestsContent?.create_placement_test_extra_time_tooltip_0
      })`;
  }
};

export const formatReminders = (notificationReminders, placementTestsContent) => {
  const newReminders =
    notificationReminders && notificationReminders.length > 0
      ? notificationReminders.map(reminder => {
          let newText;
          switch (reminder.timePeriod) {
            case 12:
              newText = placementTestsContent?.placement_test_summary_show_results_hours;
              break;
            case 24:
              newText = placementTestsContent?.placement_test_summary_show_results_day;
              break;
            case 72:
              newText = placementTestsContent?.placement_test_summary_show_results_days;
              break;
            default:
              newText = placementTestsContent?.placement_test_summary_show_results_week;
              break;
          }
          return `${newText} ${
            reminder.notificationEvent === 'BEFORE_ENDS'
              ? placementTestsContent?.placement_test_summary_show_results_reminders_ends
              : placementTestsContent?.placement_test_summary_show_results_reminders_starts
          }`;
        })
      : [];
  return newReminders;
};

export const isDefaultReminders = (reminders = []) => !!reminders.find(item => item.timePeriod === 'select');
