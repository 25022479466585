import signedFetch from './util/signedFetch.js';

/**
 * This updated the specified parts of a class. The data payload can contain any of the following:
* - platform: "am", // unique accepted value for now
* - productId: "..."" // `productid` value from the GetOpenProducts EPS API (OUP ID for this product)
* - isbn: "97...", // `isbn` value from the GetOpenProducts EPS API
* - externalProductId: ... // `assessment_master:package_id` int value
* - placementTestType: "...", // `assessment_master:package_org_code` value
* - languageVariationCode: "...", // `language_variation_code` value
* - listeningAccentsCode: "...", // `listening_accents_code` value
* - testStartDate: "string",
* - testEndDate: "string",
* - extraTimePerItem: "...", // integer
* - sendEmailsToStudents: "...", // boolean
* - notificationReminders: [
    ...,
    {
      "timePeriod": "...", // integer, in hours
      "notificationEvent": "..." // enum, supports only "BEFORE_STARTS" and "BEFORE_ENDS" for now
    },
    ...
  ],
* - showResultsToStudents: "..." // boolean
 */

export default (orgId, classroomId, data) =>
  signedFetch(
    'editClass',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/placementTest/${encodeURIComponent(classroomId)}`,
    'PUT',
    data
  );
